import StepTemplate from "../StepTemplate";
import { styled } from "styled-components";

const Result = ({
  currentStep,
  onSubmit,
  handlePrevStep,
  formData: { guests, menu },
  formSubmitted,
}) => (
  <>
    <StepTemplate
      title={
        !formSubmitted
          ? "Is this information correct?"
          : "Thanks for Submitting"
      }
      currentStep={currentStep}
      onSubmit={onSubmit}
      handlePrevStep={handlePrevStep}
      formSubmitted={formSubmitted}
    >
      {!formSubmitted ? (
        guests.map((_, index) => (
          <Wrapper key={index}>
            <Title>Guest #{index + 1}</Title>
            <div>
              <Text>
                <Heading>Forename:</Heading> {guests[index].firstName}
              </Text>
              <Text>
                <Heading>Surname:</Heading> {guests[index].surName}
              </Text>
              <Text>
                <Heading>Attendence:</Heading> {guests[index].attendence}
              </Text>
              <Text>
                <Heading>Dietery Requirement:</Heading> {guests[index].dietReq}
              </Text>
            </div>
            <div>
              <Text>
                <Heading>Starter:</Heading> {menu[index].starter}
              </Text>
              <Text>
                <Heading>Sorbet:</Heading> {menu[index].sorbet}
              </Text>
              <Text>
                <Heading>Main:</Heading> {menu[index].main}
              </Text>
              <Text>
                <Heading>Dessert:</Heading> {menu[index].dessert}
              </Text>
            </div>
          </Wrapper>
        ))
      ) : (
        <Wrapper>
          <p>
            If you need to amend any of these details,
            <br /> let one of us know.
            <br />
            We cant wait to see you there.
          </p>
        </Wrapper>
      )}
    </StepTemplate>
  </>
);

const Wrapper = styled.div`
  padding: 0.5rem 1rem;
  // border-bottom: solid #454647 1px;
`;

const Title = styled.div`
  font-size: 1.7rem;
  font-weight: 800;
  border-bottom: solid 1px #454647;
`;

const Text = styled.p`
  text-transform: capitalize;
  margin-bottom: 0.3rem;
`;

const Heading = styled.span`
  font-size: 1.4rem;
  font-weight: 800;
`;

export default Result;
