import React from "react";
import GoogleMapReact from "google-map-react";
import Pin from "./Pin";

const Map = () => {
  const loc = {
    address: "Otterburn, Newcastle upon Tyne NE19 1JX",
    lat: 55.2251841,
    lng: -2.1884446,
  };
  return (
    <div className="map">
      <div className="google-map" style={{ height: "400px" }}>
        {/* create new key using a different email to get the Google free trial again
        {/* https://console.cloud.google.com/freetrial/signup?flow=maps&project=wedding-401215&redirectPath=%2Fgoogle%2Fmaps-apis%2Fonboard;step%3Djust_ask%3Fproject%3Dwedding-401215 */}
        {/* <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyDEsVgu0eiyVwhB9OxXxNaAY0A7mH4D3uw",
          }}
          defaultCenter={loc}
          defaultZoom={14}
        >
          <Pin
            lat={55.22636127859237}
            lng={-2.189595019631323}
            text="Woodhill Hall"
          />
        </GoogleMapReact> */}
        <iframe
          title="test"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2275.564319764377!2d-2.1917651869938193!3d55.2258633727806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487decb51c2931e1%3A0x743a624b6e58f716!2sWoodhill%20Hall!5e0!3m2!1sen!2suk!4v1705683158057!5m2!1sen!2suk"
          height="400"
          width="100%"
          style={{ border: "none" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

// 55.22596127859237, -2.188745019631323;

export default Map;

// AIzaSyDEsVgu0eiyVwhB9OxXxNaAY0A7mH4D3uw
// Otterburn, Newcastle upon Tyne NE19 1JX
// 55.2259° N, 2.1892° W
