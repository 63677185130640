import React from "react";
import styled from "styled-components";
import styles from "./styles.module.css";

import { PiSuitcaseRolling } from "react-icons/pi";
import { LiaGlassCheersSolid } from "react-icons/lia";
import { GiNightSleep } from "react-icons/gi";
import { GiForkKnifeSpoon } from "react-icons/gi";
import { GiToaster } from "react-icons/gi";
import { TbToolsKitchen2Off } from "react-icons/tb";
import { LiaChairSolid } from "react-icons/lia";
import { LiaRingSolid } from "react-icons/lia";
import { CgCoffee } from "react-icons/cg";
import { MdOutlineNightlife } from "react-icons/md";
import { LiaSadCrySolid } from "react-icons/lia";
import { LuPartyPopper } from "react-icons/lu";
import { LiaWineGlassAltSolid } from "react-icons/lia";
import dance from "../../assets/imgs/dance.png";

const Timeline = ({ markers }) => {
  const showIcon = (icon) => {
    switch (icon) {
      case "case":
        return <PiSuitcaseRolling />;
      case "drinks":
        return <LiaGlassCheersSolid />;
      case "sleep":
        return <GiNightSleep />;
      case "food":
        return <GiForkKnifeSpoon />;
      case "food2":
        return <GiForkKnifeSpoon />;
      case "cocktail":
        return <MdOutlineNightlife />;
      case "egg":
        return <GiToaster />;
      case "close":
        return <TbToolsKitchen2Off />;
      case "seat":
        return <LiaChairSolid />;
      case "ring":
        return <LiaRingSolid />;
      case "coffee":
        return <CgCoffee />;
      case "night":
        return <LiaWineGlassAltSolid />;
      case "dance":
        return <img src={dance} alt="" />;
      case "tipi":
        return <LuPartyPopper />;
      case "bye":
        return <LiaSadCrySolid />;
      default:
        return "x";
    }
  };

  return (
    <div className={styles.timelineContainer}>
      <Line>
        {markers.map(({ icon, text, top, left }) => (
          <Marker
            top={top}
            left={left && "50%"}
            right={!left && "50%"}
            jc={left && "flex-end"}
          >
            <Info left={left} className={icon === "night" ? "night" : ""}>
              <Icon>{showIcon(icon)}</Icon>
              <p
                style={{
                  color: "inherit",
                  fontSize: "inherit",
                  marginBottom: "0.2rem",
                }}
                className={
                  icon === "tipi"
                    ? "extra-pad"
                    : "" || icon === "food2"
                    ? "extra-pad"
                    : ""
                }
              >
                {text}
              </p>
            </Info>
          </Marker>
        ))}
      </Line>
    </div>
  );
};

const Line = styled.div`
  height: 80vh;
  width: 8px;
  background-color: #fff;
  border-radius: 10px;

  @media only screen and (min-width: 1025px) {
    position: relative;
    height: 8px;
    width: 90vw;
  }
`;

const Marker = styled.div`
  position: absolute;
  height: 5px;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.jc};
  background-color: #fff;
  border-radius: ${(props) => (props.left ? "0 10px 10px 0" : "10px 0 0 10px")};

  @media only screen and (min-width: 1025px) {
    height: 100px;
    width: 5px;
    top: ${(props) => (props.left ? props.left : "-100px")};
    left: ${(props) => props.top};
    align-items: ${(props) => (props.left ? "flex-end" : "flex-start")};
    border-radius: ${(props) =>
      props.left ? "0 0 10px 10px" : "10px 10px 0 0"};
  }
`;

const Info = styled.div`
  padding: 0 0.5rem;
  text-align: ${(props) => (props.left ? "end" : "start")};
  color: #fff;
  font-size: 1.1rem;

  @media only screen and (max-width: 360px) {
    .extra-pad {
      margin-bottom: -1.2rem !important;
    }
  }

  // specific for phones like the Galaxy A5
  @media only screen and (max-height: 640px) {
    font-size: 1rem;
    .extra-pad {
      margin-bottom: 0 !important;
    }
  }

  @media only screen and (min-width: 1025px) {
    margin-left: ${(props) => (!props.left ? "1rem" : "0")};
    margin-right: ${(props) => (props.left ? "1rem" : "0")};
    padding: 0;
    min-width: 140px;
    font-size: 1.3rem;

    &.night {
      min-width: 100px;
    }
  }
`;

const Icon = styled.div`
  color: #fff;
  margin-top: -0.8rem;
  svg {
    font-size: 2rem;
  }
  img {
    filter: invert(1);
    max-height: 32px;
  }

  // specific for phones like the Galaxy A5
  @media only screen and (max-width: 360px) {
    margin-top: -0.4rem;
    svg {
      font-size: 1.5rem;
    }
    img {
      max-height: 24px;
    }
  }
`;

export default Timeline;
