import React from "react";
import chapel from "../../assets/imgs/chapel.png";

const Footer = () => (
  <div className="footer">
    <img src={chapel} className="chapel" alt="" />
    <div className="footer-overlay">
      <p>Copyright &#169; 2024 shelbyandreece.co.uk</p>
    </div>
  </div>
);

export default Footer;
