import React from "react";

const NoMAtch = () => (
  <div className="lost">
    <h1 className="lost-head">404</h1>
    <p className="lost-text">Looks like you've gotten lost.</p>
  </div>
);

export default NoMAtch;
