import React, { useEffect, useState } from "react";
import ListItem from "./ListItem";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Button from "../Button";
import logo from "../../assets/imgs/logo.png";

const Nav = () => {
  const [y, setY] = useState(0);
  const [showMobileNav, setShowMobileNav] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setY(window.scrollY);
    });
  }, [y]);

  useEffect(() => {
    if (showMobileNav) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [showMobileNav]);

  const LinkStyles = {
    textDecoration: "none",
    color: "inherit",
  };

  const containerVariants = {
    open: {
      opacity: 1,
      transition: {
        delayChildren: 0.5, // this will set a delay before the children start animating
        staggerChildren: 0.3, // this will set the time inbetween children animation
      },
    },
    closed: {
      opacity: 0,
    },
  };

  const variants = {
    open: {
      x: 0,
      opacity: 1,
      transition: {
        delayChildren: 2,
        staggerChildren: 0.5,
      },
    },
    closed: {
      x: -50,
      opacity: 0,
      transition: {
        x: { stiffness: 1000 },
      },
    },
  };

  const mobileNavItems = [
    <Link to="gallery" style={{ ...LinkStyles }}>
      <ListItem text="Gallery" cn="gallery" />
    </Link>,
    <a style={{ ...LinkStyles }} href="/#rsvp">
      <Button text="RSVP" cn="nav-list__item rsvp-button" onClick={() => {}} />
    </a>,
  ];

  const getCurrentLocation = () => {
    if (window.location.href.includes("gallery")) {
      window.location.assign("../#rsvp");
    }
  };

  const modalAction = (state) => {
    if (state) {
      setShowMobileNav(false);
      document.querySelector("html").style.overflow = "auto";
    } else {
      if (showMobileNav) {
        setShowMobileNav(false);
        document.querySelector("html").style.overflow = "auto";
      } else {
        setShowMobileNav(true);
        document.querySelector("html").style.overflow = "hidden";
      }
    }
  };

  return (
    <>
      <div className="nav classic">
        {/* <div className={`nav ${y > 300 ? "sticky" : "classic"}`}> */}
        <ul className="nav-list">
          <Link
            to="/"
            style={{ ...LinkStyles }}
            onClick={() => modalAction(true)}
          >
            <li className="nav-list__item">
              <img className="nav-logo" src={logo} alt="" />
            </li>
          </Link>
          <div className="nav-item__wrapper">
            <Link to="gallery" style={{ ...LinkStyles }}>
              <ListItem text="Gallery" cn="gallery" />
            </Link>
            {/* <Link to="/rsvp" style={{ ...LinkStyles }}>
              <ListItem text="RSVP" cn="rsvp" />
            </Link> */}
            <a style={{ ...LinkStyles }} href="#rsvp">
              <Button
                text="RSVP"
                cn="nav-list__item rsvp-button"
                onClick={() => getCurrentLocation()}
              />
            </a>
          </div>
          <div
            className={`hamburger ${showMobileNav ? "is-active" : ""}`}
            id="hamburger-1"
            onClick={() => modalAction()}
          >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </ul>
      </div>
      {/* mobile nav items */}
      <div className={`mobileNavItems ${showMobileNav ? "" : "hide"}`}>
        <motion.div
          variants={containerVariants}
          animate={showMobileNav ? "open" : "closed"}
          className="mobileNavItemsWrapper"
        >
          {mobileNavItems.map((item, i) => (
            <motion.li variants={variants} onClick={() => modalAction()}>
              {item}
            </motion.li>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default Nav;
