import React from "react";
import InfoBox from "../InfoBox";
import { styled } from "styled-components";
import bg from "../../assets/imgs/frame.png";

const Info = () => (
  <Cont>
    <Head className="hidden">Important information</Head>
    <InfoWrapper>
      <InfoBox
        title="Ceremony"
        text="During the ceremony we'd like everyone to be 100% present so we're kindly requesting that all phones remain in pockets for the duration. However, after the ceremony we encourage everyone to take as many photos as you like, we'll even have QR codes dotted around the site so you can upload your memories here to share with everyone."
      />
      <Img />
      <div>
        <InfoBox
          title="Venue"
          text="The venue is CARD ONLY, please bear that in mind when preparing for the day."
        />
        <br />
        <InfoBox
          title="Rooms"
          text="Rooms will be allocated by the Bride and Groom, cost will be given after final RSVP's, if you have any questions please reach out to either one of us."
        />
      </div>
    </InfoWrapper>
  </Cont>
);

const Cont = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  overflow: hidden;

  &:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 400px;
    inset: 0px 64px 0px 0px;
    transform: translateX(6rem);
    background-image: linear-gradient(rgb(253, 252, 251), rgb(226, 229, 231));
    z-index: -1;
  }

  @media only screen and (min-width: 1024px) {
    &:before {
      width: 1400px;
      transform: translateX(20rem);
    }
  }
`;

const Head = styled.h2`
  font-size: 1.9rem;
  margin-bottom: 0.8rem;
  text-align: center;

  @media only screen and (min-width: 1024px) {
    font-size: 2.6rem;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const Img = styled.div`
  height: 300px;
  width: 300px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${bg});

  @media only screen and (min-width: 1025px) {
    height: 400px;
    width: 400px;
    display: flex;
    align-self: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export default Info;
