import React from "react";
import styles from "./styles.module.css";
import { AiFillCloseCircle } from "react-icons/ai";

const Modal = ({ image, show, removeModal }) => {
  return (
    <div
      className={`${styles.modal} ${show ? styles.show : ""}`}
      onClick={(e) => removeModal(e)}
    >
      <AiFillCloseCircle
        className={styles.closeIcon}
        onClick={(e) => removeModal(e)}
      />
      <div className={styles.imgContainer} onClick={(e) => removeModal(e)}>
        <img
          className={styles.modalImage}
          src={image}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

export default Modal;
