import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import Choice from "../Choice";
import MenuOptions from "../../../../data/MenuOptions.json";
import { styled } from "styled-components";
import Toggle from "../../../Toggle";

const MenuForm = ({
  field,
  index,
  errors,
  register,
  control,
  setValue,
  getValues,
}) => {
  const { fields: guestFields } = useFieldArray({
    control,
    name: "guests",
  });

  const [kidsMenu, setKidsMenu] = useState(false);

  const menuChange = (index) => {
    setKidsMenu((currState) => !currState);
    const menu = getValues("menu");
    menu[index] = { starter: "", sorbet: "", main: "", dessert: "" };
    setValue("menu", menu);
  };

  return (
    <Wrapper key={field.id}>
      <legend>
        <Guest>{guestFields[index].firstName}</Guest>
      </legend>
      {/* KIDS MENU */}
      <Toggle
        title="Need a kids menu?"
        toggled={kidsMenu}
        onClick={() => menuChange(index)}
      />
      {!kidsMenu ? (
        // adults menu
        <>
          {/* STARTER */}
          <Choice
            course="Starter"
            options={MenuOptions.starters}
            register={{
              ...register(`menu.${index}.starter`, {
                required: "This is required.",
              }),
            }}
            error={errors?.menu?.[index]?.starter?.message}
          />
          {/* SORBET */}
          <Choice
            course="Sorbet"
            options={MenuOptions.sorbet}
            register={{
              ...register(`menu.${index}.sorbet`, {
                required: "This is required.",
              }),
            }}
            error={errors?.menu?.[index]?.sorbet?.message}
          />
          {/* MAIN */}
          <Choice
            course="Main"
            options={MenuOptions.mains}
            register={{
              ...register(`menu.${index}.main`, {
                required: "This is required.",
              }),
            }}
            error={errors?.menu?.[index]?.main?.message}
          />
          {/* DESSERT */}
          <Choice
            course="Dessert"
            options={MenuOptions.desserts}
            register={{
              ...register(`menu.${index}.dessert`, {
                required: "This is required.",
              }),
            }}
            error={errors?.menu?.[index]?.dessert?.message}
          />
        </>
      ) : (
        // kids menu
        <>
          <Choice
            course="Starter"
            options={[{ value: "garlic bread", title: "Garlic bread" }]}
            register={{
              ...register(`menu.${index}.starter`, {
                required: "This is required.",
              }),
            }}
            error={errors?.menu?.[index]?.starter?.message}
          />
          {/* SORBET */}
          <Choice
            course="Sorbet"
            options={[{ value: "raspberry", title: "Raspberry" }]}
            register={{
              ...register(`menu.${index}.sorbet`, {
                required: "This is required.",
              }),
            }}
            error={errors?.menu?.[index]?.sorbet?.message}
          />
          {/* MAIN */}
          <Choice
            course="Main"
            options={[{ value: "pizza", title: "Cheese & tomato pizza" }]}
            register={{
              ...register(`menu.${index}.main`, {
                required: "This is required.",
              }),
            }}
            error={errors?.menu?.[index]?.main?.message}
          />
          {/* DESSERT */}
          <Choice
            course="Dessert"
            options={[
              {
                value: "brownie",
                title: "Choclate brownie with vanilla ice cream",
              },
            ]}
            register={{
              ...register(`menu.${index}.dessert`, {
                required: "This is required.",
              }),
            }}
            error={errors?.menu?.[index]?.dessert?.message}
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.fieldset`
  margin: 0 1rem 1rem 1rem;
  border: solid #000;
  border-radius: 5px;
`;

const Guest = styled.h3`
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 800;
  margin-left: 0.4rem;
  margin-right: 0.6rem;
`;

export default MenuForm;
