import React from "react";
import styles from "./styles.module.css";

const Radio = ({ labelMain, labelSec, value, register, disabled }) => (
  <label className={styles.container}>
    <input
      className={styles.input}
      type="radio"
      value={value}
      {...register}
      disabled={disabled}
    />
    <div className={styles.labelWrapper}>
      <span className={styles.primaryLabel}>{labelMain}</span>
      <span className={styles.secondaryLabel}>{labelSec && labelSec}</span>
    </div>
  </label>
);

export default Radio;
