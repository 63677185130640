import React from "react";
import { styled } from "styled-components";

const InfoBox = ({ title, text }) => (
  <Box>
    <h3>{title}</h3>
    <p>{text}</p>
  </Box>
);

const Box = styled.div`
  h3 {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    letter-spacing: 1px;
    line-height: 1.3rem;
  }

  @media only screen and (min-width: 1025px) {
    // height: 200px;
    width: 400px;
  }
`;

export default InfoBox;
