import React from "react";
import styles from "./styles.module.css";

const Choice = ({ course, options, register, error }) => (
  <div className={styles.wrapper}>
    <h2>{course}</h2>
    {options.map((option) => (
      <div className={styles.detailsWrapper}>
        <p className={styles.text}>{option.title}</p>
        <input
          className={styles.radio}
          type="radio"
          value={option.value}
          {...register}
        />
      </div>
    ))}
    <p className="form-error">{error}</p>
  </div>
);

export default Choice;
