import styles from "./styles.module.css";
import { MdArrowBackIosNew, MdArrowForwardIos, MdCheck } from "react-icons/md";
import { motion } from "framer-motion";

const StepTemplate = ({
  title,
  currentStep,
  onSubmit,
  handlePrevStep,
  children,
  formSubmitted = false,
}) => (
  <form
    autoComplete="off"
    data-lpignore="true"
    className={styles.masterForm}
    onSubmit={onSubmit}
  >
    {title && <h2 className={styles.title}>{title}</h2>}

    <div className={styles.formContainer}>{children}</div>

    <div className={styles.btnWrapper}>
      <motion.button
        whileHover={{ scale: currentStep !== 1 && 1.1 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        whileTap={{ scale: 0.9 }}
        className={styles.btn}
        type="button"
        disabled={true}
        onClick={() => handlePrevStep()}
      >
        <MdArrowBackIosNew />
      </motion.button>
      <motion.button
        whileHover={{ scale: 1.1 }}
        transition={{ type: "spring", stiffness: 400, damping: 10 }}
        whileTap={{ scale: 0.9 }}
        className={styles.btn}
        type="submit"
        disabled={true}
      >
        {currentStep === 3 ? <MdCheck /> : <MdArrowForwardIos />}
      </motion.button>
    </div>
  </form>
);

export default StepTemplate;
