// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDBzRGxivSQV__QLf4F8LLTJT6OM7aJXNU",
  authDomain: "wedding-52c27.firebaseapp.com",
  projectId: "wedding-52c27",
  storageBucket: "wedding-52c27.appspot.com",
  messagingSenderId: "821120634648",
  appId: "1:821120634648:web:6ae78e1b3677800969186d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Export firestore database & storage
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
export const storage = getStorage(app);
