import React from "react";
import { motion } from "framer-motion";

const ListItem = ({ text, cn, color = "transparent", special }) => (
  <motion.div
    className={`nav-list__item ${cn}`}
    style={{ backgroundColor: color }}
    whileHover={{ scale: 1.1 }}
    transition={{ type: special && "spring", stiffness: 400, damping: 10 }}
    whileTap={{ scale: 0.9 }}
  >
    <li>{text}</li>
  </motion.div>
);

export default ListItem;
