import React from "react";
import Rsvp from "../../components/Rsvp";
import { styled } from "styled-components";

const index = () => (
  <FormContainer>
    <Rsvp />
  </FormContainer>
);

export default index;

const FormContainer = styled.div`
  min-height: 100vh;
  padding-top: 5rem;
`;
