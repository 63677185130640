import React from "react";
import styles from "./styles.module.css";

const Toggle = ({ toggled, onClick, title }) => (
  <div className={styles.container}>
    {title}
    <label className={styles.switch}>
      <input type="checkbox" checked={toggled} onClick={() => onClick()} />
      <span className={styles.slider} />
    </label>
  </div>
);

export default Toggle;
