import { useState } from "react";
import Timeline from "./Timeline";
import Button from "../Button";
import options from "../../data/MarkerOptions.json";
import styles from "./styles.module.css";

const TimeLineCont = () => {
  const [markers, setMarkers] = useState(options.wed);

  return (
    <div className={`${styles.mainContainer}`}>
      <h2 className={`${styles.timelineHead} hidden`}>Events Timeline</h2>
      <div className={styles.btnWrapper}>
        <Button
          text="Wed"
          cn={`${styles.timeLineBtn} ${
            markers.length === 4 ? styles.timeLineBtnActive : ""
          }`}
          onClick={() => setMarkers(options.wed)}
        />
        <Button
          text="Thur"
          cn={`${styles.timeLineBtn} ${
            markers.length > 4 ? styles.timeLineBtnActive : ""
          }`}
          onClick={() => setMarkers(options.thur)}
        />
        <Button
          text="Fri"
          cn={`${styles.timeLineBtn} ${
            markers.length < 4 ? styles.timeLineBtnActive : ""
          }`}
          onClick={() => setMarkers(options.fri)}
        />
      </div>
      <Timeline markers={markers} />
    </div>
  );
};

export default TimeLineCont;
