import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import Venue from "../components/Map/Venue";
import Map from "../components/Map/Map";
import SongForm from "../components/SongForm";
import TimeLineCont from "../components/Timeline/TimeLineCont";
import Info from "../components/Info";
import Rsvp from "../components/Rsvp";

const Home = () => {
  const [y, setY] = useState(0);

  const observer = new IntersectionObserver((enteries) => {
    enteries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("show");
      } else {
        entry.target.classList.remove("show");
      }
    });
  });

  const hiddenElements = document.querySelectorAll(".hidden");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setY(window.scrollY);
    });
    hiddenElements.forEach((el) => observer.observe(el));
  }, [y]);

  return (
    <>
      <Banner cn="countdown" />
      <div className="details">
        <div className="details-text">
          <h2 className="details-head hidden">
            <span className="fancy-text">We're getting married!</span>
            <br />
            MAY 16TH 2024
          </h2>
          <p className="hidden">Please join us on this most joyous day</p>
        </div>
        <Venue />
        <Map />
        <SongForm />
      </div>
      <TimeLineCont />
      <Info />
      <Rsvp />
    </>
  );
};

export default Home;
