import React from "react";
import styles from "./styles.module.css";

const Input = ({ label, placeholder, register, error, cn, disabled }) => {
  return (
    <label className={`${styles.container} ${cn}`}>
      <span className={styles.label}>{label && label}</span>
      <input
        className={styles.input}
        type="text"
        placeholder={placeholder && placeholder}
        {...register}
        disabled={disabled}
      />
      <p className="form-error">{error}</p>
    </label>
  );
};

export default Input;
