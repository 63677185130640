import { useState, useEffect } from "react";
import { storage } from "../../firebase";
import { ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import styles from "./styles.module.css";
import Modal from "./Modal";
import Button from "../../components/Button";
import { LuPartyPopper } from "react-icons/lu";

const ImageUpload = () => {
  const [image, setImage] = useState("");
  const [imageList, setImageList] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [modalImage, setModalImage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const imageListRef = ref(storage, "/");

  const resetSuccess = () => {
    setSuccess(false);
  };

  const resetInput = () => {
    setLoading(false);
    setSuccess(true);
    setImage("");
    document.querySelector("#uploadInput").value = "";
    setTimeout(resetSuccess, 3000);
  };

  const uploadImage = () => {
    if (image) {
      setLoading(true);
      // ref(where to send file, location/name of file)
      const imageRef = ref(storage, `/${image.name + v4()}`);
      // uploadBytes(ref, file to upload)
      uploadBytes(imageRef, image).then((snapshot) => {
        // what to do once the image is uploaded
        resetInput();

        getDownloadURL(snapshot.ref).then((url) => {
          setImageList((currItems) => [...currItems, url]);
        });
      });
    }
  };

  const modalAction = (img) => {
    if (showModal) {
      setShowModal(false);
      setModalImage("");
      document.querySelector("html").style.overflow = "auto";
    } else {
      setModalImage(img);
      setShowModal(true);
      document.querySelector("html").style.overflow = "hidden";
    }
  };

  useEffect(() => {
    listAll(imageListRef).then((res) => {
      res.items.map((image) => {
        getDownloadURL(image).then((url) =>
          setImageList((currItems) => {
            if (!currItems.includes(url)) {
              return [...currItems, url];
            }
          })
        );
      });
    });
  }, []);

  return (
    <>
      <Modal image={modalImage} show={showModal} removeModal={modalAction} />
      <div className={styles.container}>
        <h1 className={styles.header}>
          We'd love to see the Day from your eyes.
        </h1>
        <div className={styles.control}>
          <input
            id="uploadInput"
            className={styles.userinput}
            type="file"
            onChange={(e) => setImage(e.target.files[0])}
            disabled={disabled}
          />
          <div
            style={{
              visibility: loading || success ? "visible" : "hidden",
            }}
            className={styles.message}
          >
            {loading ? (
              <>
                <p>Loading...</p>{" "}
                <svg
                  style={{ height: "32px", width: "32px" }}
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle className="spinner" cx="16" cy="16" r="12" />
                </svg>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  opacity: success ? "1" : "0",
                  transition: "all ease-out .5s",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <p>Upload complete</p> <LuPartyPopper />
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            text="Upload"
            icon
            cn={styles.upload}
            onClick={uploadImage}
            disabled={disabled}
          />
        </div>
        {disabled ? (
          <div className={styles.galleryMessage}>
            Check back on the 15th May to start uploading memories.
          </div>
        ) : (
          <div className={styles.images}>
            {imageList.map((url) => (
              <div key={url} onClick={() => modalAction(url)}>
                <img className={styles.img} key={v4()} src={url} alt="" />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ImageUpload;
