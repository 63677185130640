const Venue = () => {
  return (
    <div className="venue">
      <img src={require("../../assets/imgs/SE3.jpg")} alt="Site" />
      <p>
        Ceremony and reception will be held at <br />
        <span
          style={{
            color: "#98BAE5",
            fontWeight: "bold",
            fontSize: "1.6rem",
            textTransform: "uppercase",
          }}
        >
          Woodhill Hall
        </span>{" "}
        <br />
        Otterburn, Northumberland, NE19 1JX
      </p>
    </div>
  );
};

export default Venue;
