import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import title from "../data/CountdownComplete.json";

const Banner = ({ img, overlay, minorTxt, majorTxt, cn }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [complete, setComplete] = useState(false);

  const [showBanner, setShowBanner] = useState(false);

  let targetDate = new Date("May 16, 2024 12:00:00 GMT").getTime();

  const countDown = () => {
    let now = new Date().getTime();
    let distance = targetDate - now;

    setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
    setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

    if (!distance) {
      setDays("00");
      setHours("00");
      setMinutes("00");
      setSeconds("00");
      setComplete(true);
      // clearInterval(cd);
    }
  };

  // const cd = setInterval(countDown, 1000);

  useEffect(() => {
    setShowBanner(true);
  }, []);

  const imgVariants = {
    show: {
      x: -180,
      opacity: 1,
      transition: {
        duration: 4,
      },
    },
    hide: {
      x: -250,
      opacity: 0,
    },
  };

  return (
    <div className="banner loaded">
      <div className={`img-container`}>
        <div className="img-wrapper">
          <motion.img
            variants={imgVariants}
            animate={showBanner ? "show" : "hide"}
            initial="hide"
            src={require(`../assets/imgs/iceland.jpg`)}
            alt=""
          />
        </div>
      </div>
      <div
        className={`text-container ${cn}`}
        style={{ backgroundColor: overlay }}
      >
        <div className="names-wrapper">
          <h1 className="banner-names">
            Shelby<span className="amp">and</span>Reece
          </h1>
        </div>
        <p className="text-minor">Countdown to the Day</p>
        {/* {complete ? ( */}
        <h2 className="countdown-phrase">
          {title.phrase[Math.floor(Math.random() * title.phrase.length)]}
        </h2>
        {/* // ) : ( */}
        {/* // <h2 */}
        {/* //   className={`countdown-timer ${days ? "" : "hideME"}`} */}
        {/* // style={{ */}
        {/* //   visibility: days ? "visible" : "hidden",
            //   transition: "all 2s ease-in-out",
            // }}
        //   >
        //     <div className="countdown-item">
        //       {days}
        //       <span>Days</span>
        //     </div>
        //     <div className="countdown-item">
        //       {hours}
        //       <span>Hours</span>
        //     </div>
        //     <div className="countdown-item">
        //       {minutes}
        //       <span>Minutes</span>
        //     </div>
        //     <div className="countdown-item"> */}
        {/* //       {seconds}
        //       <span>Seconds</span>
        //     </div>
        //   </h2>
        // )} */}
      </div>
    </div>
  );
};

export default Banner;
