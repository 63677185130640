import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import submission from "../data/SongSubmissionRes.json";
import { motion } from "framer-motion";
const SongForm = () => {
  const [name, setName] = useState("");
  const [song, setSong] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [phrase, setPhrase] = useState();

  const randonNumGen = () => {
    return Math.floor(Math.random() * 25);
  };

  const addUserSubmission = (e, state) => {
    state(e.target.value);
  };

  const addSongToDB = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "songs"), {
        songDetails: {
          guest: name,
          song,
        },
      });
      setSubmitted(true);
      setPhrase(submission.res[randonNumGen()]);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={`form-container ${submitted ? "form-submitted" : ""}`}>
      <h2 className="form-title">
        {submitted ? phrase : "What would you like to hear?"}
      </h2>
      <form
        action=""
        method="POST"
        className="song-submission-form"
        style={{ display: `${submitted ? "none" : "flex"}` }}
      >
        <label htmlFor="name">
          Your Name <span className="req">(required)</span>
        </label>
        <input
          className="name-input"
          id="name"
          type="text"
          value={name}
          onChange={(e) => addUserSubmission(e, setName)}
        />
        <label htmlFor="song">
          Artist and Song Title <span className="req">(required)</span>
        </label>
        <textarea
          id="song"
          type="text"
          value={song}
          onChange={(e) => addUserSubmission(e, setSong)}
        />
        <motion.div whileTap={{ scale: 0.9 }}>
          <input
            className="submit"
            type="submit"
            value="Submit"
            disabled={!name || !song}
            onClick={addSongToDB}
          />
        </motion.div>
      </form>
    </div>
  );
};

export default SongForm;
