import "./App.css";
import Nav from "./components/Nav";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import ImageUpload from "./pages/ImageUpload";
import NoMatch from "./pages/NoMatch";
import RsvpForm from "./pages/RSVP";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<ImageUpload />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
}

export default App;
